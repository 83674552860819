/* app/dashboard/react_components/src/utils/axiosInstance.js */

import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: "/api", // Set the base URL for your API
    withCredentials: true, // Ensure cookies are included with requests
});

export default axiosInstance;