/* app/dashboard/react_components/src/components/Dashboard/Dashboard.js */

import React, { useEffect, useState } from "react";
import axios from "../../utils/axiosInstance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Dashboard.css"; // Import CSS for styling
import DashboardLayout from "../Layout/DashboardLayout";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [users, setUsers] = useState([]); // State to store user list
  const [selectedUserId, setSelectedUserId] = useState(""); // Selected user for filtering
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currency, setCurrency] = useState("USDT"); // Default currency is USDT

  // Fetch orders data
  const fetchData = async () => {
    try {
      setLoading(true);
  
      // Prepare the parameters
      const params = {};
      if (selectedUserId) params.user_id = parseInt(selectedUserId); // Ensure it's an integer
      if (startDate) params.start_date = startDate; // Pass as ISO string
      if (endDate) params.end_date = endDate; // Pass as ISO string
      if (currency) params.currency = currency; // Include currency filter
  
      // console.log("Request Parameters:", params); // Debugging
  
      const response = await axios.get("/dashboard/orders/report", {
        params,
      });
  
      setData(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Fetch active users for dropdown
  const fetchUsers = async () => {
    try {
      const response = await axios.get("/user/users");
      setUsers(response.data);
    } catch (err) {
      console.error("Error fetching users:", err.message);
    }
  };

  useEffect(() => {
    fetchData();
    fetchUsers();
  }, []);

  return (
    <DashboardLayout>
      <div className="dashboard-container">
        <h1 className="dashboard-title">Orders Dashboard</h1>
        <div className="filters">
          <div>
            <label htmlFor="userSelect">User:</label>
            <select
              id="userSelect"
              title="Select a user to filter orders"
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
            >
              <option value="">Select User</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Currency:</label>
            <select
              title="Select currency (USDT or IRT)"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option value="USDT">USDT</option>
              <option value="IRT">IRT</option>
            </select>
          </div>
          <div>
            <label>Start Date:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy/MM/dd"
              placeholderText="Select start date"
            />
          </div>
          <div>
            <label>End Date:</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy/MM/dd"
              placeholderText="Select end date"
            />
          </div>
          <button className="search-button" onClick={fetchData} title="Fetch filtered results">
            Search
          </button>
        </div>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div className="error">Error: {error}</div>
        ) : (
          <table className="dashboard-table">
            <thead>
              <tr>
                <th>Market</th>
                <th>Total Bought</th>
                <th>Total Sold</th>
                <th>Buy Cost</th>
                <th>Sell Revenue</th>
                <th>Remaining Quantity</th>
                <th>Profit/Loss</th>
                <th>Profit/Loss %</th>
                <th>Latest Market Price</th>
                <th>Remaining Quantity Cost</th>
                <th>Break-Even Price</th>              
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.market}</td>
                  <td>{parseFloat(item.total_bought).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</td>
                  <td>{parseFloat(item.total_sold).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</td>
                  <td>{parseFloat(item.total_buy_cost).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</td>
                  <td>{parseFloat(item.total_sell_revenue).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</td>
                  <td>{parseFloat(item.remaining_quantity).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</td>
                  <td>{parseFloat(item.profit_loss).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</td>
                  <td>{parseFloat(item.profit_loss_percentage).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  <td>{parseFloat(item.latest_market_price).toLocaleString(undefined, { minimumFractionDigits: 6, maximumFractionDigits: 6 })}</td>
                  <td>{parseFloat(item.remaining_quantity_cost).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}</td>
                  <td>{parseFloat(item.break_even_price).toLocaleString(undefined, { minimumFractionDigits: 6, maximumFractionDigits: 6 })}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;