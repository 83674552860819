/* app/dashboard/react_components/src/components/Layout/DashboardLayout.js */

import React from "react";
import NavBar from "../NavBar/NavBar";
import "./DashboardLayout.css"; // Add separate layout styles

const DashboardLayout = ({ children }) => {
  return (
    <div className="layout">
      <NavBar />
      <div className="content">{children}</div>
    </div>
  );
};

export default DashboardLayout;