/* app/dashboard/react_components/src/utils/logoutHelper.js */

import axios from "./axiosInstance";

export const performLogout = async (setIsAuthenticated, navigate) => {
  try {
    // Call the backend logout API
    await axios.get("/user/logout");
    setIsAuthenticated(false); // Update authentication state
    navigate("/"); // Redirect to the login page
  } catch (error) {
    console.error("Error during logout:", error);
    alert("Failed to log out. Please try again.");
  }
};