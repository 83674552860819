/* app/dashboard/react_components/src/components/Logout/Logout.js */

import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { performLogout } from "../../utils/logoutHelper";

const Logout = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Call the centralized logout helper
    performLogout(setIsAuthenticated, navigate);
  }, [setIsAuthenticated, navigate]);

  return <div>Logging out...</div>;
};

export default Logout;