/* app/dashboard/react_components/src/components/NavBar/NavBar.js */

import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { performLogout } from "../../utils/logoutHelper";
import "./NavBar.css"; // Add styling for NavBar

const NavBar = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Call the centralized logout helper
    performLogout(setIsAuthenticated, navigate);
  };

  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="/dashboard/orders">Dashboard</Link>
        </li>
        <li>
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;