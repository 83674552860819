/* app/dashboard/react_components/src/components/Login/Login.js */

import React, { useState } from "react";
import axios from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import "./Login.css"; // Import the new CSS

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // For redirecting after login

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send login data to the back-end
      const response = await axios.post("/user/login", {
        email,
        password,
      });

      // If successful, redirect to the Orders Dashboard
      if (response.status === 200) {
        console.log("Navigating to /dashboard/orders");
        navigate("/dashboard/orders");
      }
    } catch (err) {
      // Handle errors (e.g., invalid credentials)
      setError("Invalid email or password. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <h1>Login</h1>
      {error && <div className="error">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;